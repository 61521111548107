var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"Factory MERCH"},[_c('div',{staticClass:"merch-card"},[_c('div',{staticClass:"search"},[_c('div',{staticClass:"search-box1"},[_c('el-input',{attrs:{"placeholder":"会员编号/单位名称"},model:{value:(_vm.searchApi.user),callback:function ($$v) {_vm.$set(_vm.searchApi, "user", $$v)},expression:"searchApi.user"}})],1),_c('div',{staticClass:"search-box1"},[_c('el-input',{attrs:{"placeholder":"借据号/订单号"},model:{value:(_vm.searchApi.number),callback:function ($$v) {_vm.$set(_vm.searchApi, "number", $$v)},expression:"searchApi.number"}})],1),_c('div',{staticClass:"search-box1"},[_c('el-select',{attrs:{"placeholder":"状态","clearable":""},model:{value:(_vm.searchApi.repayStatus),callback:function ($$v) {_vm.$set(_vm.searchApi, "repayStatus", $$v)},expression:"searchApi.repayStatus"}},_vm._l((_vm.stateOption),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)],1),_c('div',{staticClass:"search-box1"},[_c('el-date-picker',{attrs:{"value-format":"yyyy-MM-dd","type":"daterange","range-separator":"至","start-placeholder":"开始日期","end-placeholder":"结束日期"},model:{value:(_vm.searchApi.putoutDay),callback:function ($$v) {_vm.$set(_vm.searchApi, "putoutDay", $$v)},expression:"searchApi.putoutDay"}})],1),_c('div',{staticClass:"search-btn",on:{"click":_vm.search}},[_vm._v("查询")])]),_c('div',[_c('div',{staticClass:"table-box"},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.usersData.list,"border":"","height":"650","header-cell-style":{ background: '#F2F3F5' }}},[_c('el-table-column',{attrs:{"label":"序号","align":"center","prop":"index","width":"60"}}),_c('el-table-column',{attrs:{"prop":"address","label":"会员编号","align":"center","width":"80"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":row.userId+'',"placement":"bottom"}},[_c('div',{staticClass:"exceed"},[_vm._v(_vm._s(row.userId))])])]}}])}),_c('el-table-column',{attrs:{"prop":"address","label":"单位名称","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":row.enterprise,"placement":"bottom"}},[_c('div',{staticClass:"exceed"},[_vm._v(_vm._s(row.enterprise))])])]}}])}),_c('el-table-column',{attrs:{"prop":"address","label":"借据号","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":row.loanBalanceNo,"placement":"bottom"}},[_c('div',{staticClass:"exceed"},[_vm._v(_vm._s(row.loanBalanceNo))])])]}}])}),_c('el-table-column',{attrs:{"prop":"address","label":"放款日期","align":"center","width":"120"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":row.putoutDay,"placement":"bottom"}},[_c('div',{staticClass:"exceed"},[_vm._v(_vm._s(row.putoutDay))])])]}}])}),_c('el-table-column',{attrs:{"prop":"address","label":"贷款金额（元）","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":(row.putoutAmount*1).toFixed(2),"placement":"bottom"}},[_c('div',{staticClass:"exceed"},[_vm._v(_vm._s((row.putoutAmount*1).toFixed(2)))])])]}}])}),_c('el-table-column',{attrs:{"prop":"address","label":"还款状态","align":"center","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.repayStatus == 0)?_c('div',{staticClass:"repayStatus"},[_c('div'),_vm._v(" 未结清 ")]):_vm._e(),(row.repayStatus == 1)?_c('div',{staticClass:"repayStatus1"},[_c('div'),_vm._v(" 已结清 ")]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"prop":"address","label":"剩余总额（元）","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":row.availableAmount,"placement":"bottom"}},[_c('div',{staticClass:"exceed"},[_vm._v(_vm._s(row.availableAmount))])])]}}])}),_c('el-table-column',{attrs:{"prop":"address","label":"订单号","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":row.orderGroupId,"placement":"bottom"}},[_c('div',{staticClass:"exceed"},[_vm._v(_vm._s(row.orderGroupId))])])]}}])}),_c('el-table-column',{attrs:{"prop":"address","label":"应还款日期","align":"center","width":"120"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":row.repayDay,"placement":"bottom"}},[_c('div',{staticClass:"exceed"},[_vm._v(_vm._s(row.repayDay))])])]}}])}),_c('el-table-column',{attrs:{"prop":"switch","label":"实际还款日期","align":"center","width":"120"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":row.realRepayDay,"placement":"bottom"}},[_c('div',{staticClass:"exceed"},[_vm._v(_vm._s(row.realRepayDay))])])]}}])}),_c('el-table-column',{attrs:{"prop":"address","label":"操作","align":"center","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"userBtn"},[_c('div',{on:{"click":function($event){return _vm.openAdd(row)}}},[_vm._v("详情")])])]}}])})],1)],1)]),_c('div',{staticClass:"merch-page"},[_c('div',{staticClass:"pages"},[_vm._v(" 共"),_c('span',[_vm._v(_vm._s(_vm.usersData.totalPage))]),_vm._v("页/"),_c('span',[_vm._v(_vm._s(_vm.usersData.totalRecords))]),_vm._v(" 条数据 ")]),_c('el-pagination',{attrs:{"background":"","page-size":_vm.usersData.per_page,"layout":" prev, pager, next, jumper","total":_vm.usersData.totalRecords,"current-page":_vm.searchApi.currPage},on:{"current-change":_vm.handleCurrentChange}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }